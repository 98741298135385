<template>
  <div class="tip-box">
    <div class="imgifo" @mouseover="showTooltip" @mouseleave="hideTooltip">
      <img class="information1" src="/assets/sabre-img/information.png" alt="information1">
      <img class="information2" src="/assets/sabre-img/information-hover.png" alt="information2">
    </div>
    <div class="tip" v-if="ticketModal" :class="{'d-block' : isShowTooltip}" @mouseover="showTooltip" @mouseleave="hideTooltip">
      <div class="RightListBox card">
        <div class="card-body">
          <ul class="list-group list-group-flush">
            <li class="list-group-item"> מושב נבחר <div class="box color1"></div>
            </li>
            <li class="list-group-item"> מושב סטנדרטי <div class="box color2"></div>
            </li>
            <li class="list-group-item"> שורת יציאה <div class="box color3"></div>
            </li>
            <li class="list-group-item"> לא פנוי <div class="box color4"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="tip" v-else :class="{'d-block' : isShowTooltip}" @mouseover="showTooltip" @mouseleave="hideTooltip">
      <h5>{{$t("sabre.search-result.box.baggage")}}</h5>
      <p>
        <span>
          <img src="/assets/sabre-img/trolley_allow.png" alt="trolley_allow" v-if="allowCabinBaggage">
          <img src="/assets/sabre-img/trolley_disallow.png" alt="trolley_disallow" v-else>
        </span>
        {{ cabinBaggageDescription || defaultText }}
      </p>
      <p>
        <span>
          <img src="/assets/sabre-img/Suitcase_allow.png" alt="Suitcase_allow" v-if="allowCheckinBaggage">
          <img src="/assets/sabre-img/Suitcase_disallow.png" alt="Suitcase_disallow" v-else>
        </span>
        {{ checkinBaggageDescription || defaultText }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ticketModal: {
      type: Boolean,
      default: false,
    },
    allowCheckinBaggage: {
      type: Boolean,
      default: false,
    },
    allowCabinBaggage: {
      type: Boolean,
      default: false,
    },
    cabinBaggageDescription: {
      type: String,
      default: '',
    },
    checkinBaggageDescription: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShowTooltip: false,
      defaultText: 'ללורם איפסום דולור סיט אמט, נסקטורר אדיפיסינג אלית ושבעגט ליבם סולגק.',
    };
  },
  methods: {
    showTooltip() {
      this.isShowTooltip = true;
    },
    hideTooltip() {
      this.isShowTooltip = false;
    },
  },
};
</script>
